/**
 * Groups the Array by an element's property as the key
 * @param {array} arr Array to group
 * @param {string} key String that acts as the key for the element's property to group the array by
 * @returns An object containing the array grouped by the target array property (key)
 */
const groupArrayByKey = (arr, key) => {
    if (!arr) throw new Error("Oops please provide the array");
    if (!key) throw new Error("Oops, please provide the key to group the array by");
    return arr.reduce((r, a) => {
        r[a[key]] = [...r[a[key]] || [], a];
        return r;
    }, {});
}
export default groupArrayByKey;