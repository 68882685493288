import { useState } from "react";
import { Card, Input, Button, Typography, List, Divider, message, Select, Switch, } from "antd";
import Papa from "papaparse";
import groupArrayByKey from "./utils/groupArrayByKey";
const { Title, Text } = Typography;


function organizeByOrg(chosenOrg: string, datByChoice: any) {
    const peeps = {};

    console.log(Object.keys(datByChoice))
    try {
        for (let cNum in datByChoice) {
            // @ts-ignore
            const people = datByChoice[cNum][chosenOrg].map(el => ({ name: el["Nama Lengkap"], email: el["Email Address"] }));
            // @ts-ignore
            peeps[cNum] = people;
        }
        console.log("peeps", peeps);

        message.success("Processed")
    } catch (err) {
        message.error("An error happened, please check if your chosen organization is correct and the csv is proper")
        console.error(err);
    }

    return peeps;
}

function processData(chosenOrg: string, dataku: any[]) {
    console.log("dataku", dataku)
    const datByChoice = {
        "Pilihan 1": groupArrayByKey(dataku, "PILIHAN 1 -  TIDAK diperbolehkan untuk memilih SCO yang sama "),
        "Pilihan 2": groupArrayByKey(dataku, "PILIHAN 2 -  TIDAK diperbolehkan untuk memilih SCO yang sama "),
        "Pilihan 3": groupArrayByKey(dataku, "PILIHAN 3 -  TIDAK diperbolehkan untuk memilih SCO yang sama "),
        "Pilihan 4": groupArrayByKey(dataku, "PILIHAN 4 -  TIDAK diperbolehkan untuk memilih SCO yang sama "),
        "Pilihan 5": groupArrayByKey(dataku, "PILIHAN 5 -  TIDAK diperbolehkan untuk memilih SCO yang sama "),
        "Pilihan 6": groupArrayByKey(dataku, "PILIHAN 6 -  TIDAK diperbolehkan untuk memilih SCO yang sama "),
    }


    const hasilScorp = organizeByOrg(chosenOrg, datByChoice);
    return hasilScorp;
}

function ReadCsv() {
    const [textData, setTextData] = useState("");
    // const [parsedData, setParsedData] = useState<{ [key: string]: any } | null>(null);
    const [hasil, setHasil] = useState({});
    const [chosenOrg, setChosenOrg] = useState("SCOPH");
    const [showEmail, setShowEmail] = useState<boolean>(true);


    const readAndProcess = (csvData: string) => {

        const dat = Papa.parse(csvData, { header: true })
        const parsedData = dat.data;
        console.log("parsedData", parsedData)
        // setParsedData(parsedData);

        const processed = processData(chosenOrg,
            // @ts-ignore
            parsedData)

        setHasil(processed)
    }


    const RenderByData = ({ showEmail = false, myDat }: { showEmail?: boolean, myDat: any }) => {
        let itemList = [<Divider>{chosenOrg}</Divider>];
        for (let choiceNum in myDat) {
            itemList.push(<Divider>{choiceNum} ({myDat[choiceNum].length} people)</Divider>)
            itemList.push(<List dataSource={myDat[choiceNum]}
                renderItem={(item: any) => (
                    <List.Item>
                        <Typography.Text>{item.name}{showEmail && <>&nbsp;<Text type="secondary"><small>({item.email})</small></Text></>}</Typography.Text>
                        {/* <Typography.Text>{item.email}</Typography.Text> */}
                    </List.Item>
                )}
            />)
        }

        return <>
            {itemList}
        </>
    }


    return <Card >
        <Title>CIMSA Sorter</Title>
        <Text>Copy paste the csv of the first sheet, select your organization, then process results</Text>
        <Divider>Input CSV</Divider>
        <Input.TextArea onChange={(e) => {
            setTextData(e.target.value)
        }} style={{ width: "100%" }} />
        <Button block type="primary" onClick={() => readAndProcess(textData)}>Process Results</Button>
        <Divider>Chosen Org</Divider>
        <Switch onChange={setShowEmail} checked={showEmail} checkedChildren="Showing Email" unCheckedChildren="Hiding Email" />
        <Select style={{ width: "100%" }} defaultValue={chosenOrg}
            options={[
                { value: 'SCOPH', label: 'SCOPH' },
                { value: 'SCOPE', label: 'SCOPE' },
                { value: 'SCORE', label: 'SCORE' },
                { value: 'SCOME', label: 'SCOME' },
                { value: 'SCORA', label: 'SCORA' },
                { value: 'SCORP', label: 'SCORP' },
            ]}
            onSelect={(val) => setChosenOrg(val)}
        />

        <RenderByData myDat={hasil} showEmail={showEmail} />


    </Card>


}
export default ReadCsv;